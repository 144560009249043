.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    background-color: #87638a;
    background: linear-gradient(194deg, #87638a -47%, #050712 72.4%, #87638a 110%);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
}
.loader div {
    background:linear-gradient(#ffc107, #87638a);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 10px),#fff 0);
    mask:radial-gradient(farthest-side,#0000 calc(100% - 10px),#fff 0);
    border-radius: 50%;  
    height: 150px;
    width: 150px;
    /* position: fixed;
    inset : calc(50% - 100px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; */
    animation: spin 1s linear infinite; 
  }
  
  @keyframes spin {
     100% { transform: rotate(360deg); }
  }
  
  /* body {
    background:linear-gradient(to right,grey,white)
  } */