.tap_section {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}
.gold-shadow {
    position: absolute;
    top: 80px;
    width: 70px;
    height: 60px;
    background-color: #e7a61a36;
    -webkit-box-shadow: 13px 92px 99px 97px #e7a61a;
    -moz-box-shadow: 13px 92px 99px 97px #e7a61a;
    box-shadow: 13px 92px 99px 97px #e7a61a;
}
.points_section .points img {
    position: relative;
    /* top: 2px; */
}
.points_section .points span {
    color: #ffff;
    font-size: 2rem;
    font-weight: bold;
}
.points_section .trophy {
    /* color: #cecece; */
    font-weight: 600;
}
.points_section .trophy .fa-angle-right {
    font-size: 12px;
    position: relative;
    top: 2px;
}
.tap_section .footer {
    position: absolute;
    bottom: 0px;
}
.tap-progress_section {
    position: absolute;
    bottom: 85px;
    left: 0;
    right: 0;
    width: 95%;
}


.coinTap_section {
    position: relative;
    touch-action: none;
    margin-top: 40px;
}
.plus-one {
    position: absolute;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    pointer-events: none;
    animation: moveUp 1s ease-out forwards;
}

@keyframes moveUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-150px);
    }
}

.coinTap_section img {
    width: 80%;
    /* height: 225px; */
    margin-top: 20px;
    border-bottom-right-radius: 46%;
    border-bottom-left-radius: 46%;
    border-top-right-radius: 60%;
    border-top-left-radius: 60%;
    background-color: #ffffff00;
}

.coinTap_section img.clicked {
    animation: dipEffect 0.2s ease-in-out;
    /* animation: pulse 0.25s infinite; */
}

.speed-tapping {
    background: url(../../utils/images/electricBolt.png);
    background-repeat: no-repeat;
    background-size: 100% 125%;;
    background-position: center;
}

@keyframes dipEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95) translateY(5px);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes pulse {
    0% {
      opacity: 1;
      box-shadow: 0 0 30px #00c6ff, 0 0 60px #ffa600, 0 0 90px #ff007f;
    }
    50% {
      opacity: 0.8;
      box-shadow: 0 0 15px #00c6ff, 0 0 30px #ffa600, 0 0 45px #ff007f;
    }
    100% {
      opacity: 1;
      box-shadow: 0 0 30px #00c6ff, 0 0 60px #ffa600, 0 0 90px #ff007f;
    }
  }