.task_tab {
    font-size: 15px;
}
.task-tab_section.nav-tabs {
    border-bottom: none !important;
}
.task_tab {
    color: #fff;
    position: relative;
}
.task_tab.active {
    background-color: var(--bg_basic);
    border-radius: 5px;
    /* color: black; */
}
.task_tab .badge {
    position: absolute;
    top: 3px;
    right: 4px;
    border-radius: 50%;
    /* background-color: #E97451; */
    height: 12px;
    width: 10px;
    color: transparent;
}
.goldBtn {
    background-color: #ffa600;
}
.claimed-success {
    color: #4BB543 !important;
}