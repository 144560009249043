:root {
  --bg_basic: #67636352;
  --muted_color: #cecece;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.app {
  height: 100vh;
}

h6 {
  font-size: 14px !important;
}

.main_section {
  max-width: 500px;
  margin: 0 auto;
  background-color: #87638a;
  background: linear-gradient(194deg, #87638a -47%, #050712 72.4%, #87638a 110%);
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.main-section {
  overflow: auto;
  scrollbar-width: none;
}
.muted-color {
  color: var(--muted_color);
}

.basic-gradient {
  background-color: #3e187a;
  background-image: linear-gradient(0deg, #3e187a 0%, #994ecc 105%);
}

.loadingBtn {
  color: #cecece7a !important;
  background-color: var(--bg_basic) !important;
  background-image: none !important;
}

.section_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #252121b0 !important;
  color: #5f5454f7;
  z-index: 2;
  border-radius: 8px;
  cursor: default !important;
}
.section_overlay img {
  opacity: 0.4;
}