.tab_box {
    background-color: var(--bg_basic);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 18%;
}
.menu-text {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}
.tab_box.active {
    background-color: #e7a61a1c;
    border: 1px solid #e7a61a;
}