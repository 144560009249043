.taskPad {
    background-color: var(--bg_basic);
    position: relative;
}

.start-mission {
    width: 100%;
    height: 50px;
    display: flex;
    color: #fff;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
}
.end-mission {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    color: #cecece7a;
    /* color: var(--muted_color); */
    background-color: var(--bg_basic);
    font-size: 18px;
    font-weight: 600;
}

.go-mission {
    text-decoration: none;
    color: #fff;
    border-radius: 15px;
    font-size: 16px;
}
.wallet_link {
    color: #c113d0;
}
.mission-tasks h6 {
    color: #858484;
}

.connect_wallet p {
    font-size: 14px;
}


.blinking {
    animation: blinker 2s linear infinite;
    color: #0dcaf0;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.done {
    color: #00C076;
    font-weight: bold;
    font-size: 14px;
}
