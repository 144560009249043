.refTask-tab_section .claim_link {
    text-decoration: none;
    color: #fff;
    background-color: #ffc107;
    padding: 3px 12px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
  }
.refTask-tab_section .notClaim_link {
    text-decoration: none;
    color: #fff;
    background-color: var(--bg_basic);
    padding: 3px 12px;
    border-radius: 5px;
    font-weight: 600;
}

.refTask-tab_section .progress {
    height: 15px;
    border: 2px solid var(--bg_basic);
}
.refTask-tab_section .progress-bar {
    background-color: purple;
    border-radius: 20px;
}