.progressCount span{
    color: #fff;
}
.progressCount .tap_count {
    font-size: 12px;
    position: relative;
    top: 1px;
}
.progressCount .progress {
    height: 15px;
    border: 2px solid var(--bg_basic);
}
.progressCount .progress .bg-warning {
    background-color: #ffa600;
}